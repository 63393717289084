// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("@rails/activestorage").start()

require("stimulus")

require("trix")
require("@rails/actiontext")

require('mediainfo.js')

require("channels")

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const images = require.context('../images', true)
const imagePath = (name) => images(name, true)

require("@mdi/font/css/materialdesignicons")

require("packs/brands/tickets/select_users")

import "../stylesheets/application";

document.addEventListener('DOMContentLoaded', () => {

  // Get all "navbar-burger" elements
  const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);

  // Check if there are any navbar burgers
  if ($navbarBurgers.length > 0) {

    // Add a click event on each of them
    $navbarBurgers.forEach( el => {
      el.addEventListener('click', () => {

        // Get the target from the "data-target" attribute
        const target = el.dataset.target;
        const $target = document.getElementById(target);

        // Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
        el.classList.toggle('is-active');
        $target.classList.toggle('is-active');

      });
    });
  }

  for (const element of document.querySelectorAll('.notification > .delete')) {
    element.addEventListener('click', e => {
      e.target.parentNode.classList.add('is-hidden');
    });
  }

  const dropdowns = document.querySelectorAll('.dropdown:not(.is-hoverable)')

  if (dropdowns.length > 0) {
    dropdowns.forEach(function (el) {
      const triggers = el.getElementsByClassName('dropdown-trigger')
      Array.prototype.forEach.call(triggers, trigger => {
        trigger.addEventListener('click', function (event) {
          event.stopPropagation()
          el.classList.toggle('is-active')
        })
      })
    })

    document.addEventListener('click', function (event) {
      closeDropdowns()
    })
  }

  function closeDropdowns() {
    dropdowns.forEach(function (el) {
      el.classList.remove('is-active')
    })
  }

  document.querySelectorAll(('.keeps-dropdown-open')).forEach((el) => {
    el.addEventListener('click', (event) => {
      // closeDropdowns が呼ばれないようにしている。
      event.stopPropagation()
    })
  })
});
