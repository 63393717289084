function initApp() {
  var container = document.getElementById('select_users');
  if (typeof(container) != 'undefined' && container != null) {
    loadedHandler_()
  }
}

function loadedHandler_() {
  var ac = document.getElementById('all')
  ac.addEventListener('click', function() {
    var su = document.select_users;
    var tf = this.checked;
    for(var i = 0; i < su.elements.length ; i++) {
      su.elements[i].checked = tf; // ON・OFFを切り替え
    }
  })
}

document.addEventListener('DOMContentLoaded', initApp);
